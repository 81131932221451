<template>
  <v-card class="charcoal mt-2">
    <v-card-actions class="pa-0 mt-n">
      <v-spacer />
      <BaseActionButton
        xlarge
        tonal
        block
        color=""
        href
        class="progressActive text-h6"
        icon="mdi-login-variant"
        label="Login or Register"
        @clickedThis="$router.push('/login')"
      />
    </v-card-actions>
    <v-card-text
      :class="
        textSizeXSmall +
          '  px-2 text-center charcoal--text text--lighten-3 pb-0'
      "
    >
      <p class=" mb-0">Best workout builder & nutrition log.</p>
      <p class=" mb-0">Take control of your workouts. Free.</p>
    </v-card-text>
    <GetApp />
    <v-row dense class="mx-2 mt-2">
      <v-carousel
        v-model="main"
        cycle
        :continuous="false"
        delimiter-icon="mdi-stop"
        prev-icon="mdi-arrow-left"
        next-icon="mdi-arrow-right"
        show-arrows-on-hover
        :show-arrows="!isPhone"
        height="200"
        class="elevation-10 charcoal charcoalTile"
      >
        <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
          :src="isPhone ? slide.image_small : slide.image"
          reverse-transition="fab-transition"
          transition="fab-transition"
        >
          <v-sheet :color="slide.color" height="100%" class="opacity-80">
            <v-card-text :class="textSizeSmall + ' pa-3 paper--text'">
              <span v-html="slide.text" :class="isPhone ? 'px-0' : 'px-10'" />
            </v-card-text>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-row>

    <template>
      <v-card-actions class="mb-2">
        <v-spacer />
        <v-btn
          @click="showStats = !showStats"
          title="Show Global Stats"
          text
          class="silver--text"
        >
          {{ showStats ? 'Hide' : 'Show' }} Global Stats
        </v-btn>
      </v-card-actions>
      <v-sheet v-if="showStats" class=" transparent px-0 pb-5">
        <v-row class="px-2" v-if="loaded">
          <v-col cols="12" md="6">
            <v-sheet
              rounded
              class="transparent silver--text"
              :min-height="isPhone ? 120 : 200"
              :style="charcoalTile"
            >
              <v-card-text class="py-0 transparent px-4">
                our users are
              </v-card-text>
              <v-card-actions :class="textSize + '  px-2 '">
                PUMPING IRON
              </v-card-actions>
              <v-row align="center" justify="center">
                <v-card-text :class="textSizeSmall + ' pb-0'">
                  <v-card-title :class="textSizeXSmall + ' pa-0 pl-2'">
                    IRON PUMPED: Last 30 days
                  </v-card-title>
                  <v-divider class="my-2 silver" />
                  <v-card-text :class="textSizeXSmall">
                    <span class="pl-2 smallLabel">
                      Volume:
                    </span>
                    {{
                      parseFloat(volumeStats.volume_imperial).toLocaleString()
                    }}
                    lbs /
                    {{ parseFloat(volumeStats.volume_metric).toLocaleString() }}
                    kg <br />

                    <span class="pl-2 smallLabel">
                      Selfs:
                    </span>
                    {{ parseFloat(volumeStats.selfs).toLocaleString() }}
                    selfs <br />
                    <span class=" pl-2 smallLabel">
                      Reps:
                    </span>
                    {{ parseFloat(reps.reps).toLocaleString() }}
                    <br />
                    <span class="pl-2 smallLabel">
                      Time:
                    </span>
                    {{ volumeStats.duration }} hours of weightlifting
                  </v-card-text>
                  <v-divider class="my-2 silver" />
                  <v-card-actions class="py-0 pb-5 progressActive--text">
                    AVG Workout Length
                    <v-spacer />
                    {{ volumeStats.workout_length }} minutes
                  </v-card-actions>
                </v-card-text>
              </v-row>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="6">
            <v-sheet
              rounded
              class="transparent  silver--text"
              :min-height="isPhone ? 120 : 200"
              :style="charcoalTile"
            >
              <v-card-text class="py-0 px-4">
                &
              </v-card-text>
              <v-card-actions :class="textSize + '  px-2'">
                GOING FAR
              </v-card-actions>
              <v-row align="center" justify="center">
                <v-card-text :class="textSizeSmall">
                  <v-card-title :class="textSizeXSmall + ' pa-0 pl-2'">
                    TRAVELED: Last 30 days
                  </v-card-title>
                  <v-divider class="my-2 silver" />
                  <v-card-text :class="textSizeXSmall">
                    <span class="  pl-2 smallLabel">
                      Distance:
                    </span>
                    {{ moving.distance_imperial }} miles /
                    {{ moving.distance_imperial }} km <br />
                    <span class=" pl-2 smallLabel">
                      Time:
                    </span>
                    {{ moving.hours }} hours of movement<br />
                    <span class="  pl-2 smallLabel">
                      Burned:
                    </span>
                    {{ parseFloat(moving.calories).toLocaleString() }}
                    calories <br />
                    <span class="  pl-2 smallLabel">
                      Melted:
                    </span>
                    {{ moving.burned }} lbs of fat
                  </v-card-text>
                  <v-divider class="my-2 silver" />
                  <v-card-actions class="progressActive--text py-0">
                    AVG Calories Burned
                    <v-spacer />
                    {{ moving.calories_per_minute }} per minute
                  </v-card-actions>
                </v-card-text>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </template>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row dense class="">
          <v-col cols="12" md="6">
            <v-hover v-slot="{ hover }" open-delay="150" close-delay="200">
              <v-card
                :min-height="isPhone ? 0 : 355"
                rounded
                class="charcoal paper--text charcoalTile"
                href
                to="/workouts/database"
                :elevation="hover ? 3 : 1"
              >
                <v-card-actions :class="textSize + ' paper--text pl-4 pt-3'">
                  Workouts
                  <v-spacer />
                  <v-icon class="paper--text mt-n2" large>
                    mdi-gesture-tap
                  </v-icon>
                </v-card-actions>
                <v-card-subtitle class="pt-0 paper--text">
                  Build and Track Workouts
                </v-card-subtitle>
                <v-card-text class="silver--text">
                  Take your fitness journey to the next level with our advanced
                  yet user-friendly tool designed to help you build and track
                  personalized workouts. With complete control over the number
                  of exercises, sets, reps, and rest times, you can tailor each
                  workout to meet your specific goals. Save and easily reuse
                  your workout plans, while tracking essential metrics such as
                  volume, effort, and one-rep max. Whether you're into
                  weightlifting, Circuit (HIIT), or Hybrid (Cross-training or
                  WOD), our platform supports a wide range of workout styles.
                  Explore our public workout database, create your own from
                  scratch, and unlock your full potential today!
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6">
            <v-hover v-slot="{ hover }" open-delay="150" close-delay="200">
              <v-card
                :min-height="isPhone ? 0 : 355"
                rounded
                class="charcoal paper--text charcoalTile"
                href
                to="/exercise/database"
                :elevation="hover ? 3 : 1"
              >
                <template>
                  <v-card-actions :class="textSize + ' paper--text pl-4 pt-3'">
                    Exercises Database
                    <v-spacer />
                    <v-icon class="paper--text mt-n2" large>
                      mdi-gesture-tap
                    </v-icon>
                  </v-card-actions>
                  <v-card-subtitle class="pt-0 paper--text">
                    Discover and Learn Exercises
                  </v-card-subtitle>
                  <v-card-text class="silver--text">
                    Immerse yourself in our comprehensive database of exercises,
                    offering a wide variety of weightlifting, bodyweight, and
                    cardio exercises. Gain valuable insights into targeted
                    muscles, forces involved, and receive detailed instructions
                    and tips for proper form and execution. Expand your exercise
                    repertoire, improve your technique, and optimize your
                    workouts with our extensive exercise library.
                  </v-card-text>
                </template>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6">
            <v-hover v-slot="{ hover }" open-delay="150" close-delay="200">
              <v-card
                :min-height="isPhone ? 0 : 250"
                rounded
                class="charcoal paper--text charcoalTile"
                href
                to="/musclepedia"
                :elevation="hover ? 3 : 1"
              >
                <v-card-actions :class="textSize + ' paper--text pl-4 pt-3'">
                  Musclepedia
                  <v-spacer />
                  <v-icon class="paper--text mt-n2" large>
                    mdi-gesture-tap
                  </v-icon>
                </v-card-actions>
                <v-card-text class="silver--text">
                  Expand your knowledge and understanding of your body with
                  Musclepedia, a comprehensive tool that brings a wealth of
                  information to your fingertips. Explore the active muscles
                  involved in different exercises and gain insights into their
                  functions. With detailed illustrations and information on
                  muscle relationships, you'll have a deeper understanding of
                  your body's mechanics and how to optimize your workouts.
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6">
            <v-hover v-slot="{ hover }" open-delay="150" close-delay="200">
              <v-card
                :min-height="isPhone ? 0 : 250"
                class="charcoal paper--text charcoalTile"
                rounded
                href
                to="/clocks"
                :elevation="hover ? 3 : 1"
              >
                <v-card-actions :class="textSize + ' paper--text pl-4 pt-3'">
                  Fitness Clocks
                  <v-spacer />
                  <v-icon class="paper--text mt-n2" large>
                    mdi-gesture-tap
                  </v-icon>
                </v-card-actions>
                <v-card-text class="silver--text">
                  Stay on track with our specially designed fitness clocks. From
                  classic Tabata to HIIT workouts, our clocks will help you
                  maintain the perfect pace. If you're into Crossfit-style
                  workouts, we've got you covered with AMRAP, EMOM, and "For
                  Time" clocks. Seamlessly integrated with our workout manager,
                  you can track and pace your workouts effectively. Stay
                  focused, meet your goals, and optimize your training sessions
                  with our reliable fitness clocks.
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6">
            <v-hover v-slot="{ hover }" open-delay="150" close-delay="200">
              <v-card
                :min-height="isPhone ? 0 : 250"
                rounded
                class="charcoal paper--text charcoalTile"
                href
                to="/calculators/all"
                :elevation="hover ? 3 : 1"
              >
                <v-card-actions :class="textSize + ' paper--text pl-4 pt-3'">
                  Calculators
                  <v-spacer />
                  <v-icon class="paper--text mt-n2" large>
                    mdi-gesture-tap
                  </v-icon>
                </v-card-actions>
                <v-card-text class="silver--text">
                  Gain insights into vital metrics about your body and progress
                  with our range of calculators. From calculating your Basal
                  Metabolic Rate (BMR) and Total Daily Energy Expenditure (TDEE)
                  to determining Body Fat Percentage (BFP) and One-Rep Max
                  (1RM), our calculators empower you to understand and optimize
                  your fitness journey. Measure, track, and achieve your goals
                  with confidence using our comprehensive suite of calculators.
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6">
            <v-hover v-slot="{ hover }" open-delay="150" close-delay="200">
              <v-card
                :min-height="isPhone ? 0 : 250"
                rounded
                class="charcoal paper--text charcoalTile"
                href
                to="/standards"
                :elevation="hover ? 3 : 1"
              >
                <v-card-actions :class="textSize + ' paper--text pl-4 pt-3'">
                  Strength Standards
                  <v-spacer />
                  <v-icon class="paper--text mt-n2" large>
                    mdi-gesture-tap
                  </v-icon>
                </v-card-actions>
                <v-card-text class="silver--text">
                  Explore our Strength Standards tool and discover where you
                  stand in terms of strength. Get valuable insights and guidance
                  on advancing your strength journey with relative strength
                  information for major lifts like the bench press, deadlift,
                  back squat, and shoulder (overhead) press. Track your progress
                  with graphs displaying the evolution of your 1RM lifts. Unlock
                  your true potential and push your limits with our public
                  Strength Standards tool.
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import axios from 'axios'
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'
import slides from '@/json/homepage-slides.json'
const GetApp = () =>
  import(/* webpackPrefetch: true */ '@/components/blocks/GetApp.vue')
export default {
  components: { GetApp },
  mixins: [util],
  data: () => ({
    main: 0,
    slides: slides,
    volumeStats: {},
    invites: [],
    reps: {},
    moving: {},
    loaded: false,
    showStats: false
  }),
  beforeMount() {
    if (this.isPhone && this.isFirstVisit) this.$router.push('/login')

    this.loadVolumeStats()
  },
  beforeDestroy() {},
  computed: {
    gradient() {
      return 'to top, rgba(220,250,250,.0), rgba(255,240,255,.2)'
    },
    ...appConfig
  },
  methods: {
    loadVolumeStats() {
      return axios
        .get(this.baseURL + '/stats/open/volume30', {})
        .then(response => {
          if (response.status == 200) {
            this.volumeStats = response.data.data['volume']
            this.reps = response.data.data['reps']
            this.moving = response.data.data['moving']
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
